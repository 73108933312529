import React, { useState, useEffect } from "react";
import Close from "../../../../../icons/close";
import Trash from "../../../../../icons/trash";
import axios from "axios";
import Papa from "papaparse";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
interface farmacia {
  farmacia_id: number;
  nombre: string;
}
interface Producto {
  producto_id: number;
  nombre: string;
  articulo: string;
  cantidad: number;
  unidad: string;
  cantidad_por_caja: number;
}

const AddingFarmaciaInventory = ({ isVisible, setIsVisible }: Props) => {
  const [selectedFarmacias, setSelectedFarmacias] = useState([] as farmacia[]);
  const [selectedFarmacia, setSelectedFarmacia] = useState("" as any);
  const [farmacias, setFarmacias] = useState([] as farmacia[]);
  const [selectedProducts, setSelectedProducts] = useState<Producto[]>([]);

  const removeFarmacia = (farmaciaId: number) => {
    setSelectedFarmacias((prevFarmacias) =>
      prevFarmacias.filter((farmacia) => !(farmacia.farmacia_id === farmaciaId))
    );
  };
  const handleFarmaciaChange = (event: any) => {
    const selectedValue = event.target.value;

    const selectedFarmacia = farmacias.find(
      (farmacia) => farmacia.nombre === selectedValue
    );

    if (selectedFarmacia) {
      setSelectedFarmacia(selectedFarmacia);
      console.log("Selected Farmacia:", selectedFarmacia);
    } else {
      console.log("No matching farmacia found");
    }
  };
  const getFarmacias = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/farmacias/allFarmacias`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.data;
      setFarmacias(data);
    } catch (error) {
      console.error(error);
    }
  };
  const newFarmacia = () => {
    if (selectedFarmacia) {
      setSelectedFarmacias((prevFarmacias) => [
        ...prevFarmacias,
        selectedFarmacia,
      ]);
      setSelectedFarmacia("");
    } else {
      console.log("No farmacia selected");
    }
  };
  const openFileSelector = () => {
    const fileInput = document.getElementById("csvInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    } else {
      console.error("The file input element was not found.");
    }
  };
  const handleCSVUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const products = results.data;
          console.log(products);
          updateSelectedFarmaciasFromCSV(products);
        },
      });
    }
  };

  const updateSelectedFarmaciasFromCSV = (products: any) => {
    const newSelectedProducts = products.map((product: any) => {
      return {
        producto_id: product.producto_id,
        nombre: product.nombre,
        articulo: product.articulo,
        cantidad: product.cantidad,
      };
    });

    setSelectedProducts(newSelectedProducts);
  };

  useEffect(() => {
    getFarmacias();
  }, []);

  return (
    <>
      {isVisible ? (
        <div className={`${isVisible ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div className="h-full bg-white p-2 w-screen md:w-1/3 px-6">
            <div className="w-full sticky top-0 bg-white flex items-center my-4">
              <p className="text-xl">
                <strong>Modificar Inventario</strong>
              </p>

              <div className="grow" />
              <button onClick={() => setIsVisible(!isVisible)}>
                <Close />
              </button>
            </div>
            <div className="flex flex-col gap-4 mt-4">
              <div>
                <label htmlFor="name">Farmacia</label>
                <input
                  type="text"
                  id="producto_id"
                  name="producto_id"
                  onChange={handleFarmaciaChange}
                  list="names"
                  className="main-input"
                  required
                />
                <datalist id="names">
                  {farmacias.map((farmacia) => (
                    <option
                      key={`${farmacia.farmacia_id}`}
                      value={`${farmacia.nombre}`}
                    />
                  ))}
                </datalist>
                <button
                  className="cefa-button"
                  onClick={newFarmacia}
                  type="button"
                >
                  Agregar farmacia
                </button>
              </div>
              <div
                className="overflow-auto bg-white pb-8 md:pb-0"
                style={{ maxHeight: "calc(60vh)" }}
              >
                <table className="w-full">
                  <thead className="bg-gray-50 top-0 sticky">
                    <tr className="table-head text-left">
                      <th>ID</th>
                      <th>Farmacia</th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-[#FAFAFA]">
                    {selectedFarmacias.length === 0 ? (
                      <tr className="hover:bg-slate-300">
                        <td className="text-center" colSpan={6}>
                          No hay farmacias seleccionadas
                        </td>
                      </tr>
                    ) : (
                      selectedFarmacias.map((farmacia) => (
                        <tr
                          className="hover:bg-slate-100 table-body "
                          key={farmacia.farmacia_id}
                        >
                          <td>{farmacia.nombre}</td>
                          <td>
                            <div className="m-auto w-fit">
                              <button
                                onClick={() =>
                                  removeFarmacia(farmacia.farmacia_id)
                                }
                              >
                                <Trash size={15} />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className="border border-gray-300 rounded-lg shadow-md flex flex-col items-center cursor-pointer"
                onClick={openFileSelector}
              >
                <p className="p-4">Click para subir reporte</p>
                <input
                  id="csvInput"
                  type="file"
                  accept=".csv"
                  className="hidden"
                  onChange={(event) => {
                    console.log("test");
                    handleCSVUpload(event);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AddingFarmaciaInventory;
