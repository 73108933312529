import PremioIcon from "../../../../icons/menu/premio";
import RetoIcon from "../../../../icons/menu/reto";
import PedidoIcon from "../../../../icons/menu/pedido";
// import RetoComprobanteIcon from "../../../../icons/menu/reto_comprobante";
// import VentaComprobanteIcon from "../../../../icons/menu/venta_comprobante";
import ProductoIcon from "../../../../icons/menu/producto";
import ReporteIcon from "../../../../icons/menu/reporte";
import HomeIcon from "../../../../icons/menu/home";
import MarcaIcon from "../../../../icons/menu/marca";
import UsuarioIcon from "../../../../icons/menu/perfil";
import AyudaIcon from "../../../../icons/menu/ayuda";
import Menu from "../../../../icons/menu";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import ModalWithCarousel from "../misc/ModalCarrucel";

interface Props {
  view: string;
  setView: React.Dispatch<React.SetStateAction<string>>;
}

const AdminMenu = ({ view, setView }: Props) => {
  const navigate = useNavigate();
  const [state, setState] = useState(false);

  const handleLogout = () => {
    Swal.fire({
      title: "Estas seguro que quieres salir?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Salir!",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        navigate("/");
      }
    });
  };
  return (
    <div className="md:w-2/12 bg-[#FAFAFA] md:h-full p-2 flex flex-col gap-1 min-w-full">
      <div className="flex">
        <div className="m-auto w-fit">
          <img
            src={require("../../../../icons/Logo-Celmar.png")}
            alt=""
            className="max-w-[200px] h-auto"
          />
        </div>
        <div className="grow" />
        <button onClick={() => setState(!state)} className="md:hidden inline">
          <Menu />
        </button>
      </div>
      {state ? (
        <div className="flex flex-col gap-2">
          <button
            className={`menu-item ${view === "Home" ? "active" : ""}`}
            onClick={() => {
              setView("Home");
              setState((prevState) => !prevState);
            }}
          >
            <HomeIcon />
            <p>Inicio</p>
          </button>
          <button
            className={`menu-item ${view === "Premios" ? "active" : ""}`}
            onClick={() => setView("Premios")}
          >
            <PremioIcon />
            <p>Premios</p>
          </button>
          <button
            className={`menu-item ${view === "Retos" ? "active" : ""}`}
            onClick={() => setView("Retos")}
          >
            <RetoIcon />
            <p>Retos</p>
          </button>
          <button
            className={`menu-item ${view === "Pedidos" ? "active" : ""}`}
            onClick={() => setView("Pedidos")}
          >
            <PedidoIcon />
            <p>Pedidos</p>
          </button>
          <button
            className={`menu-item ${view === "Marca" ? "active" : ""}`}
            onClick={() => setView("Marca")}
          >
            <MarcaIcon />
            <p>Marca</p>
          </button>
          {/* <button
            className="menu-item"
            onClick={() => setView("RetoComprobantes")}
          >
            <RetoComprobanteIcon />
            <p>Reto comprobantes</p>
          </button>
          <button
            className="menu-item"
            onClick={() => setView("VentaComprobantes")}
          >
            <VentaComprobanteIcon />
            <p>Venta comprobantes</p>
          </button> */}
          <button
            className={`menu-item ${view === "Productos" ? "active" : ""}`}
            onClick={() => setView("Productos")}
          >
            <ProductoIcon />
            <p>Productos</p>
          </button>
          <button
            className={`menu-item ${view === "Perfil" ? "active" : ""}`}
            onClick={() => setView("Productos")}
          >
            <UsuarioIcon />
            <p>Perfil</p>
          </button>
          <button
            className={`menu-item ${view === "Ayuda" ? "active" : ""}`}
            onClick={() => setView("Productos")}
          >
            <AyudaIcon />
            <p>Ayuda</p>
          </button>
          <button className="menu-item" onClick={handleLogout}>
            <ReporteIcon />
            <p>Salir</p>
          </button>
        </div>
      ) : null}
      <div className="flex-col hidden gap-2 md:flex grow">
        <button
          className={`menu-item ${view === "Home" ? "active" : ""}`}
          onClick={() => setView("Home")}
        >
          <HomeIcon />
          <p>Inicio</p>
        </button>
        <button
          className={`menu-item ${view === "Premios" ? "active" : ""}`}
          onClick={() => setView("Premios")}
        >
          <PremioIcon />
          <p>Premios</p>
        </button>
        <button
          className={`menu-item ${view === "Retos" ? "active" : ""}`}
          onClick={() => setView("Retos")}
        >
          <RetoIcon />
          <p>Retos</p>
        </button>
        <button
          className={`menu-item ${view === "Pedidos" ? "active" : ""}`}
          onClick={() => setView("Pedidos")}
        >
          <PedidoIcon />
          <p>Pedidos</p>
        </button>
        <button
          className={`menu-item ${view === "Marca" ? "active" : ""}`}
          onClick={() => setView("Marca")}
        >
          <MarcaIcon />
          <p>Marca</p>
        </button>
        {/* <button
          className="menu-item"
          onClick={() => setView("RetoComprobantes")}
        >
          <RetoComprobanteIcon />
          <p>Reto comprobantes</p>
        </button>
        <button
          className="menu-item"
          onClick={() => setView("VentaComprobantes")}
        >
          <VentaComprobanteIcon />
          <p>Venta comprobantes</p>
        </button> */}
        <button className="menu-item" onClick={() => setView("Productos")}>
          <ProductoIcon />
          <p>Productos</p>
        </button>
        <div className="grow"></div>
        {/* <ModalWithCarousel /> */}
        <button
          className={`menu-item ${view === "Perfil" ? "active" : ""}`}
          onClick={() => setView("Perfil")}
        >
          <UsuarioIcon />
          <p>Perfil</p>
        </button>
        <button
          className={`menu-item ${view === "Ayuda" ? "active" : ""}`}
          onClick={() => setView("Ayuda")}
        >
          <AyudaIcon />
          <p>Ayuda</p>
        </button>
        <button className="menu-item" onClick={handleLogout}>
          <ReporteIcon />
          <p>Salir</p>
        </button>
      </div>
    </div>
  );
};

export default AdminMenu;
