import axios from "axios";
import { useEffect, useState } from "react";
import ViewIcon from "../../../../../icons/view";
import View from "./View";

interface pedido {
  pedido_id: number;
  estado: string;
  fecha_creacion: string;
  nombre: string;
  apellido: string;
  email: string;
}

interface Props {
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const PedidosList = ({ counter, setCounter }: Props) => {
  const [pedidos, setPedidos] = useState<pedido[]>([]);
  const [pedidoView, setPedidoView] = useState(false);
  const [pedidoViewID, setPedidoViewID] = useState<number>(0);
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const generatePageNumbers = () => {
    return Array.from(
      { length: 5 },
      (_, index) => currentPage - 2 + index
    ).filter((page) => page >= 1 && page <= totalPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    getPedidos(page);
  };

  const getPedidos = async (page = 1) => {
    try {
      const query = `page=${page}&search=${encodeURIComponent(
        searchText
      )}&state=${encodeURIComponent(statusFilter)}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/pedidos/?${query}`,
        {
          headers: { "auth-token": sessionStorage.getItem("token") },
        }
      );
      setCurrentPage(response.data.pageInfo.currentPage);
      setTotalPages(response.data.pageInfo.totalPages);
      setPedidos(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getPedidos(currentPage);
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [searchText, statusFilter]);

  useEffect(() => {
    getPedidos(currentPage);
  }, []);

  return (
    <div className="px-2 flex flex-col">
      <div>
        <input
          type="text"
          placeholder="Buscar"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="main-input"
        />
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="main-dropdown my-2"
        >
          <option value="">Todos</option>
          <option value="Pendiente">Pendiente</option>
          <option value="Aprobado">Aprobado</option>
          <option value="En camino">En camino</option>
        </select>
      </div>
      <div
        className="overflow-auto bg-white pb-8 md:pb-0"
        style={{ maxHeight: "calc(68vh)" }}
      >
        <table className="w-full">
          <thead className="bg-gray-50 top-0 sticky">
            <tr className="table-head">
              <th className="text-left">Estado</th>
              <th className="text-left">ID</th>
              <th className="text-left">Nombre</th>
              <th className="text-left">Email</th>
              <th className="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {pedidos.length === 0 ? (
              <tr className="hover:bg-slate-300">
                <td className="text-center" colSpan={6}>
                  No hay pedidos disponibles
                </td>
              </tr>
            ) : (
              pedidos.map((pedido) => (
                <tr
                  className="hover:bg-slate-100 table-body"
                  key={pedido.pedido_id}
                >
                  <td>{pedido.estado}</td>
                  <td>{pedido.pedido_id}</td>
                  <td>{pedido.nombre}</td>
                  <td>{pedido.email}</td>

                  <td>
                    <div className="flex gap-2 items-centers justify-center">
                      <button
                        onClick={() => {
                          setPedidoView(!pedidoView);
                          setPedidoViewID(pedido.pedido_id);
                        }}
                      >
                        <ViewIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div>
        {pedidos.length > 0 && (
          <div className="flex justify-center items-center gap-4 mt-4">
            <button
              className={`${
                currentPage === 1 ? "hidden" : "cefa-button w-fit"
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Anterior
            </button>

            <div className="flex gap-2">
              {generatePageNumbers().map((page) => (
                <button
                  key={page}
                  className={`cefa-button ${
                    currentPage === page ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>

            <button
              className={`${
                currentPage === totalPages ? "hidden" : "cefa-button w-fit"
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Siguiente
            </button>
          </div>
        )}
      </div>
      <View
        counter={counter}
        setCounter={setCounter}
        pedidoView={pedidoView}
        setPedidoView={setPedidoView}
        pedidoViewID={pedidoViewID}
      />
    </div>
  );
};

export default PedidosList;
