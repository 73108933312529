import { useEffect, useState } from "react";
import axios from "axios";
import Close from "../../../../../icons/close";
import Swal from "sweetalert2";

interface producto {
  nombre: string;
  descripcion: string;
  precio: number;
  articulo: string;
  casa: string;
  cantidad_por_caja: number;
}

interface Props {
  view: boolean;
  setView: React.Dispatch<React.SetStateAction<boolean>>;
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const ProductoNew = ({ view, setView, counter, setCounter }: Props) => {
  const [producto, setProducto] = useState<producto>({} as producto);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const data = {
        nombre: producto.nombre,
        descripcion: producto.descripcion,
        precio: producto.precio,
        articulo: producto.articulo,
        casa: producto.casa,
        cantidad_por_caja: producto.cantidad_por_caja,
      };

      await axios.post(`${process.env.REACT_APP_API_URL}/api/productos`, data, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": `${sessionStorage.getItem("token")}`,
        },
      });
      setCounter(counter + 1);
      setView(!view);
      Swal.fire({
        title: "Producto creada",
        icon: "success",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      console.error(error);
      let message = "Error desconocido al crear el producto";
      if (axios.isAxiosError(error) && error.response) {
        // Check if it's an Axios error and has a response
        message =
          error.response.data.message || "Error al procesar la solicitud";
      }

      Swal.fire({
        title: "Error al crear el producto",
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setProducto({ ...producto, [name]: value });
  };

  useEffect(() => {}, []);

  return (
    <>
      {view ? (
        <div className={`${view ? "bg-drawer" : "hidden"}`}>
          <div className="grow"></div>
          <div className="h-full bg-white p-2 w-screen md:w-1/3 px-6">
            <div className="w-full sticky top-0 bg-white flex items-center my-4">
              <p className="text-xl">
                <strong>Nuevo producto</strong>
              </p>
              <div className="grow" />
              <button onClick={() => setView(!view)}>
                <Close />
              </button>
            </div>
            <div className="w-full">
              <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
                <div>
                  <label>Nombre*</label>
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    onChange={handleInputChange}
                    className="main-input"
                    required
                  />
                </div>
                <div>
                  <label>Descripcion</label>
                  <textarea
                    id="descripcion"
                    name="descripcion"
                    className="main-input h-24"
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label>Celmarmonedas*</label>
                  <input
                    type="number"
                    id="precio"
                    name="precio"
                    onChange={handleInputChange}
                    className="main-input"
                    required
                  />
                </div>
                <div>
                  <label>Cantidad Por Caja*</label>
                  <input
                    type="number"
                    id="cantidad_por_caja"
                    name="cantidad_por_caja"
                    onChange={handleInputChange}
                    className="main-input"
                    required
                  />
                </div>
                <div>
                  <label>Articulo*</label>
                  <input
                    type="text"
                    id="articulo"
                    name="articulo"
                    onChange={handleInputChange}
                    className="main-input"
                    required
                  />
                </div>
                <div>
                  <label>Casa</label>
                  <input
                    type="text"
                    id="casa"
                    name="casa"
                    onChange={handleInputChange}
                    className="main-input"
                  />
                </div>
                <button className={`cefa-button`}>Crear Producto</button>
              </form>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProductoNew;
