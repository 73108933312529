import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useTokenExpiration = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const isTokenExpired = (token: any) => {
      const decodedJwt = JSON.parse(atob(token.split(".")[1]));
      return decodedJwt.exp < Date.now() / 1000;
    };

    if (!token || isTokenExpired(token)) {
      sessionStorage.clear();
      navigate("/");
    }
  }, [navigate]);
};
export default useTokenExpiration;
