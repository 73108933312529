import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import AWS from "aws-sdk";

const ModalPreview = () => {
  const [pictureOneCarouselModalPreview, setPictureOneCarouselModalPreview] =
    useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const s3 = new AWS.S3({
    accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
  });

  const getCarouselModal = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publicidad/modal`
      );

      console.log(response.data);
      const tempImageOne = response.data.find(
        (image: any) => image.placement === "modalPictureOne"
      );
      console.log(tempImageOne);
      if (tempImageOne) {
        const paramsS3 = {
          Bucket: process.env.REACT_APP_S3_BUCKET!,
          Key: tempImageOne.link,
          Expires: 60 * 60,
        };

        s3.getSignedUrl("getObject", paramsS3, (err, url) => {
          if (err) {
            console.error(err);
          } else {
            console.log("URL:", url);
            setPictureOneCarouselModalPreview(url);
          }
          setLoading(false);
        });
      } else {
        console.warn("No image found for modalPictureOne");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching carousel modal:", error);
      setLoading(false);
    }
  };

  const showModal = () => {
    Swal.fire({
      html: pictureOneCarouselModalPreview
        ? `<img src="${pictureOneCarouselModalPreview}" alt="Modal Preview" style="width:100%;height:auto;"/>`
        : `<p>Image not available</p>`,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.disableButtons();
        setTimeout(() => Swal.enableButtons(), 5000);
      },
    });
  };

  useEffect(() => {
    getCarouselModal();
  }, []);

  useEffect(() => {
    if (!loading) {
      showModal();
    }
  }, [loading]);

  if (loading) return <p>Loading...</p>;

  return null; // Nothing is rendered; the modal handles the UI
};

export default ModalPreview;
