import { useEffect, useState } from "react";
import ImageUploader from "../../misc/ImageUploader";
import AWS from "aws-sdk";
import Swal from "sweetalert2";
import axios from "axios";
import Save from "../../../../../icons/save";
import VideoUploader from "../../misc/VideoUploader";
import Video from "../../misc/Video";
import ImageZoom from "../../misc/ImageWithZoom";
import CarouselOne from "./CarouselOne";
import CarouselTwo from "./CarouselTwo";
import ModalWithCarousel from "./Modal";

const Publicidad = () => {
  const [desktopLandingImage, setDesktopLandingImage] = useState<File>();
  const [mobileLandingImage, setMobileLandingImage] = useState<File>();
  const [videoOne, setVideoOne] = useState<File>();
  const [videoTwo, setVideoTwo] = useState<File>();
  const [desktopLandingImagePreview, setDesktopLandingImagePreview] =
    useState<string>("");
  const [mobileLandingImagePreview, setMobileLandingImagePreview] =
    useState<string>("");
  // const [desktopLandingImageURL, setDesktopLandingImageURL] =
  //   useState<string>("");
  // const [mobileLandingImageURL, setMobileLandingImageURL] =
  //   useState<string>("");
  // const [videoOneURL, setvideoOneURL] = useState<string>("");
  // const [videoTwoURL, setVideoTwoURL] = useState<string>("");

  const s3 = new AWS.S3({
    accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
  });
  s3.config.update({ signatureVersion: "v4" });

  // const getVideos = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/api/publicidad/videos`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "auth-token": `${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     );

  //     let tempVideoOne = response.data.filter(
  //       (image: any) => image.placement === "VideoOne"
  //     );
  //     let tempVideoTwo = response.data.filter(
  //       (image: any) => image.placement === "VideoTwo"
  //     );

  //     if (tempVideoOne.length > 0) {
  //       let paramsS3 = {
  //         Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
  //         Key: `${tempVideoOne[0].link}`,
  //         Expires: 60 * 60,
  //       };
  //       s3.getSignedUrl("getObject", paramsS3, (err, url: any) => {
  //         if (err) {
  //           console.error(err);
  //         } else {
  //           setVideoOne(url);
  //         }
  //       });
  //     }

  //     if (tempVideoTwo.length > 0) {
  //       let paramsS3 = {
  //         Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
  //         Key: `${tempVideoTwo[0].link}`,
  //         Expires: 60 * 60,
  //       };
  //       s3.getSignedUrl("getObject", paramsS3, (err, url: any) => {
  //         if (err) {
  //           console.error(err);
  //         } else {
  //           setVideoTwo(url);
  //         }
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getLandingImages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publicidad/landing`
      );
      let tempImageOne = response.data.filter(
        (image: any) => image.placement === "LandingDesktop"
      );
      let tempImageTwo = response.data.filter(
        (image: any) => image.placement === "LandingMobile"
      );

      let paramsS3 = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
        Key: `${tempImageOne[0].link}`,
        Expires: 60 * 60,
      };

      s3.getSignedUrl("getObject", paramsS3, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setDesktopLandingImagePreview(url);
        }
      });

      paramsS3 = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
        Key: `${tempImageTwo[0].link}`,
        Expires: 60 * 60,
      };
      s3.getSignedUrl("getObject", paramsS3, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setMobileLandingImagePreview(url);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLanding = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Swal.fire({
      title: "Uploading...",
      text: "Please wait while we upload the images.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      if (desktopLandingImage) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${desktopLandingImage?.name}`,
          Body: desktopLandingImage,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=LandingDesktop`,
          {
            image: `publicidad/${desktopLandingImage?.name}`,
            external_url: "",
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }

      if (mobileLandingImage) {
        const uploadParams = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
          Key: `publicidad/${mobileLandingImage?.name}`,
          Body: mobileLandingImage,
        };
        await s3.upload(uploadParams).promise();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/publicidad?type=LandingMobile`,
          {
            image: `publicidad/${mobileLandingImage?.name}`,
            external_url: "",
          },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${sessionStorage.getItem("token")}`,
            },
          }
        );
      }
      Swal.fire({
        icon: "success",
        title: "Images uploaded successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred during the upload.",
      });
      console.error("Error uploading file:", error);
    }
  };

  // const handleMarca = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   Swal.fire({
  //     title: "Uploading...",
  //     text: "Please wait while we upload the images.",
  //     allowOutsideClick: false,
  //     didOpen: () => {
  //       Swal.showLoading();
  //     },
  //   });

  //   try {
  //     if (videoOne) {
  //       const uploadParams = {
  //         Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
  //         Key: `publicidad/${videoOne?.name}`,
  //         Body: videoOne,
  //       };
  //       await s3.upload(uploadParams).promise();
  //       await axios.post(
  //         `${process.env.REACT_APP_API_URL}/api/publicidad?type=videoOne`,
  //         {
  //           image: `publicidad/${videoOne?.name}`,
  //           external_url: "",
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             "auth-token": `${sessionStorage.getItem("token")}`,
  //           },
  //         }
  //       );
  //     }

  //     if (videoTwo) {
  //       const uploadParams = {
  //         Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
  //         Key: `publicidad/${videoTwo?.name}`,
  //         Body: videoTwo,
  //       };
  //       await s3.upload(uploadParams).promise();
  //       await axios.post(
  //         `${process.env.REACT_APP_API_URL}/api/publicidad?type=videoTwo`,
  //         {
  //           image: `publicidad/${videoTwo?.name}`,
  //           external_url: "",
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             "auth-token": `${sessionStorage.getItem("token")}`,
  //           },
  //         }
  //       );
  //     }
  //     Swal.fire({
  //       icon: "success",
  //       title: "Images uploaded successfully",
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //   } catch (error) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "An error occurred during the upload.",
  //     });
  //     console.error("Error uploading file:", error);
  //   }
  // };

  useEffect(() => {
    // getVideos();
    getLandingImages();
  }, []);

  return (
    <div className="overflow-auto max-h-screen">
      <form action="" onSubmit={handleLanding} className="">
        <div className="flex gap-2">
          <h1>Landing</h1>
          <button>
            <Save size={26} />
          </button>
        </div>
        <div className="flex gap-4 p-2 border border-slate-200 rounded m-2">
          <div className="grow">
            <h1>Imagen para el landing page (escritorio)</h1>
            {/* <input
              value={desktopLandingImageURL}
              type="text"
              className="main-input"
            /> */}
            <ImageUploader
              setSelectedFile={setDesktopLandingImage}
              inputId="LandingDesktop"
            />
            <p>
              <strong>
                <strong>Previo</strong>
              </strong>
            </p>
            <ImageZoom imageUrl={desktopLandingImagePreview} />
          </div>
          <div className="grow">
            <h1>Imagen para el landing page (mobile)</h1>
            {/* <input
              value={mobileLandingImageURL}
              type="text"
              className="main-input"
            /> */}
            <ImageUploader
              setSelectedFile={setMobileLandingImage}
              inputId="LandingMobile"
            />
            <p>
              <strong>
                <strong>Previo</strong>
              </strong>
            </p>
            <ImageZoom imageUrl={mobileLandingImagePreview} />
          </div>
        </div>
      </form>
      {/* <form action="" onSubmit={handleMarca}>
        <div className="flex gap-2">
          <h1>Seccion Marca</h1>
          <button>
            <Save size={26} />
          </button>
        </div>
        <div className="p-2 border border-slate-200 rounded m-2">
          <h1>Video #1</h1>
          <VideoUploader setSelectedFile={setVideoOne} inputId="VideoOne" />
          <p>
            <strong>
              <strong>Previo</strong>
            </strong>
          </p>

          <Video videoUrl={videoOne} />
        </div>
        <div className="p-2 border border-slate-200 rounded m-2">
          <h1>Video #2</h1>
          <VideoUploader setSelectedFile={setVideoOne} inputId="VideoTwo" />
          <p>
            <strong>
              <strong>Previo</strong>
            </strong>
          </p>
          <Video videoUrl={videoTwo} />
        </div>
      </form> */}
      {/* <CarouselOne />
      <CarouselTwo /> */}
      <ModalWithCarousel />
    </div>
  );
};

export default Publicidad;
