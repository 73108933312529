import React, { useState } from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import axios from "axios";

interface Fecha {
  initialDate: Date;
  finalDate: Date;
}

const ExcelExport = () => {
  const [fecha, setFecha] = useState<{
    initialDate: string;
    finalDate: string;
  }>({
    initialDate: "",
    finalDate: "",
  });

  const [fechaPedidos, setFechaPedidos] = useState<{
    initialDate: string;
    finalDate: string;
  }>({
    initialDate: "",
    finalDate: "",
  });

  const [fechaAprobaciones, setFechaAprobaciones] = useState<{
    initialDate: string;
    finalDate: string;
  }>({
    initialDate: "",
    finalDate: "",
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, dataset } = event.target;
    const targetFecha = dataset.fecha;

    if (targetFecha === "fecha") {
      setFecha((prev) => ({ ...prev, [name]: value }));
    } else if (targetFecha === "fechaPedidos") {
      setFechaPedidos((prev) => ({ ...prev, [name]: value }));
    } else if (targetFecha === "fechaAprobaciones") {
      setFechaAprobaciones((prev) => ({ ...prev, [name]: value }));
    }
  };

  const exportToCSV = async (fileName: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/report/usuarios`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      const responseData = response.data;

      const dataToExport = responseData.map((item: any) => ({
        nombre: item.nombre,
        apellido: item.apellido,
        email: item.email,
        puntos: item.puntos,
        farmacia: item.farmacia,
      }));

      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    } catch (error) {
      console.error("Error exporting data to Excel:", error);
    }
  };

  const handleSubmitComprobantes = async (event: any) => {
    try {
      event.preventDefault();
      const data = {
        initialDate: fecha.initialDate,
        finalDate: fecha.finalDate,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/report/comprobantes`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const responseData = response.data;

      const transformedData = await responseData.flatMap((item: any) =>
        item.products.map((product: any) => ({
          estado: item.estado,
          fecha_creacion: item.fecha_creacion,
          usuario_nombre: item.usuario_nombre,
          usuario_apellido: item.usuario_apellido,
          usuario_email: item.usuario_email,
          farmacia: item.farmacia_nombre,
          numero_factura: item.numero_factura,
          producto_nombre: product.producto_nombre,
          cantidad: product.cantidad,
          unidad: product.unidad,
          puntos: product.puntos,
        }))
      );

      const ws = XLSX.utils.json_to_sheet(transformedData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });

      FileSaver.saveAs(dataBlob, "comprobantes.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitPedidos = async (event: any) => {
    try {
      event.preventDefault();

      const data = {
        initialDate: fechaPedidos.initialDate,
        finalDate: fechaPedidos.finalDate,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/report/pedidos`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const responseData = response.data;

      const transformedData = await responseData.flatMap((pedido: any) =>
        pedido.items.map((item: any) => ({
          pedido_id: pedido.pedido_id,
          usuario_id: pedido.usuario_id,
          estado: pedido.estado,
          fecha_creacion: pedido.fecha_creacion,
          premio_id: item.premio_id,
          premio_nombre: item.premio_nombre,
          cantidad: item.cantidad,
        }))
      );

      const ws = XLSX.utils.json_to_sheet(transformedData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });

      FileSaver.saveAs(dataBlob, "pedidos_report.xlsx");
    } catch (error) {
      console.error("Error generating pedidos report:", error);
    }
  };

  const handleSubmitHistorialAprobaciones = async (event: any) => {
    try {
      event.preventDefault();

      const data = {
        initialDate: fechaAprobaciones.initialDate,
        finalDate: fechaAprobaciones.finalDate,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/report/historial-aprobaciones`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const responseData = response.data;

      const transformedData = responseData.map((record: any) => ({
        history_id: record.venta_comprobante_action_history_id,
        action: record.action,
        action_date: record.action_date,
        puntos_antes: record.puntos_antes,
        puntos_despues: record.puntos_despues,
        venta_fecha_creacion: record.venta_fecha_creacion,
        numero_factura: record.numero_factura,
        usuario: record.usuario_nombre,
        admin: record.admin_nombre_completo,
      }));

      const ws = XLSX.utils.json_to_sheet(transformedData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });

      FileSaver.saveAs(dataBlob, "historial_aprobaciones_report.xlsx");
    } catch (error) {
      console.error("Error generating historial aprobaciones report:", error);
    }
  };
  return (
    <>
      <div className="flex flex-col gap-5 p-4">
        <button
          className="cefa-button"
          onClick={() => exportToCSV("Reportes Usuarios")}
        >
          Reporte Usuarios
        </button>
        <form action="" onSubmit={handleSubmitComprobantes}>
          <div className="flex gap-4">
            <div className="grow">
              <label htmlFor="initialDate" className="block">
                Fecha inicial
              </label>
              <input
                className="p-2 border border-gray-300 rounded-md"
                type="date"
                name="initialDate"
                value={fecha.initialDate || ""}
                onChange={handleInputChange}
                data-fecha="fecha"
              />
            </div>
            <div className="grow">
              <label htmlFor="finalDate" className="block">
                Fecha final
              </label>
              <input
                className="p-2 border border-gray-300 rounded-md"
                type="date"
                name="finalDate"
                value={fecha.finalDate || ""}
                onChange={handleInputChange}
                data-fecha="fecha"
                required
              />
            </div>
          </div>

          <button className="cefa-button my-3">Reporte Comprobantes</button>
        </form>

        <form action="" onSubmit={handleSubmitPedidos}>
          <div className="flex gap-4">
            <div className="grow">
              <label htmlFor="initialDatePedidos">Fecha inicial pedidos</label>
              <input
                className="p-2 border border-gray-300 rounded-md"
                type="date"
                name="initialDate"
                value={fechaPedidos.initialDate || ""}
                onChange={handleInputChange}
                data-fecha="fechaPedidos"
              />
            </div>
            <div className="grow">
              <label htmlFor="finalDatePedidos">Fecha final pedidos</label>
              <input
                className="p-2 border border-gray-300 rounded-md"
                type="date"
                name="finalDate"
                value={fechaPedidos.finalDate || ""}
                onChange={handleInputChange}
                data-fecha="fechaPedidos"
                required
              />
            </div>
          </div>

          <button className="cefa-button my-3">Reporte pedidos</button>
        </form>

        <form action="" onSubmit={handleSubmitHistorialAprobaciones}>
          <div className="flex gap-4">
            <div className="grow">
              <label htmlFor="initialDateAprobaciones">
                Fecha inicial aprobaciones
              </label>
              <input
                className="p-2 border border-gray-300 rounded-md"
                type="date"
                name="initialDate"
                value={fechaAprobaciones.initialDate || ""}
                onChange={handleInputChange}
                data-fecha="fechaAprobaciones"
              />
            </div>
            <div className="grow">
              <label htmlFor="finalDateAprobaciones">
                Fecha final aprobaciones
              </label>
              <input
                className="p-2 border border-gray-300 rounded-md"
                type="date"
                name="finalDate"
                value={fechaAprobaciones.finalDate || ""}
                onChange={handleInputChange}
                data-fecha="fechaAprobaciones"
                required
              />
            </div>
          </div>

          <button className="cefa-button my-3">
            Reporte Comprobantes aprobados
          </button>
        </form>
      </div>
    </>
  );
};

export default ExcelExport;
