import AWS from "aws-sdk";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const PasswordReset = () => {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const s3 = new AWS.S3({
    accessKeyId: `${process.env.REACT_APP_AWS_KEYID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_ACCESSKEY}`,
  });
  s3.config.update({ signatureVersion: "v4" });

  const handleSubmit = async (event: any) => {
    try {
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      event.preventDefault();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/password-reset`,
        {
          email,
        }
      );

      if (
        response.status === 200 &&
        response.data.message === "User not found"
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No existe usuario con ese correo electrónico",
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Solicitud enviada",
          text: "Revisa tu correo electrónico para restablecer tu contraseña",
          showCancelButton: false,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/landing");
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [imageOne, setImageOne] = useState("");
  const [imageTwo, setImageTwo] = useState("");

  const handleGetImages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/publicidad/landing`
      );

      let tempImageOne = response.data.filter(
        (image: any) => image.placement === "LandingDesktop"
      );
      let tempImageTwo = response.data.filter(
        (image: any) => image.placement === "LandingMobile"
      );

      let paramsS3 = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
        Key: `${tempImageOne[0].link}`,
        Expires: 60 * 60,
      };
      s3.getSignedUrl("getObject", paramsS3, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setImageOne(url);
        }
      });

      paramsS3 = {
        Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
        Key: `${tempImageTwo[0].link}`,
        Expires: 60 * 60,
      };
      s3.getSignedUrl("getObject", paramsS3, (err, url) => {
        if (err) {
          console.error(err);
        } else {
          setImageTwo(url);
        }
      });

      setImageTwo(tempImageTwo[0].link);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetImages();
  }, []);

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="w-1/2 h-full hidden lg:block">
        <img src={imageOne} alt="" className="h-full object-cover" />
      </div>
      <div className="w-full lg:w-1/2 bg-white flex items-center justify-center px-6 md:px-0">
        <div className="w-full max-w-md">
          <div className="">
            <img
              src={require("../../icons/Logo-Celmar.png")}
              alt=""
              className="w-40"
            />
          </div>
          <div className="">
            <img
              src={imageTwo}
              alt=""
              className="w-full h-40 object-cover rounded-md"
            />
          </div>
          <div className="max-w-md w-full">
            <form
              action=""
              className="flex flex-col gap-4 mb-5"
              onSubmit={handleSubmit}
            >
              <div className="block">
                <label htmlFor="" className="">
                  Correo Electrónico
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="main-input"
                  required
                />
              </div>

              <button className={`cefa-button`} type="submit">
                Solicitar contraseña
              </button>
              <hr />
              <button
                className={`cefa-button`}
                type="button"
                onClick={() => navigate("/")}
              >
                Volver
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
