import axios from "axios";
import { useEffect, useState } from "react";
import Trash from "../../../../../icons/trash";
import ViewIcon from "../../../../../icons/view";
import View from "./View";
import Swal from "sweetalert2";
import Status from "../../../../../icons/status";

interface Props {
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

type Premio = {
  premio_id: number;
  nombre: string;
  puntos: number;
  cantidad: number;
  estado: string;
};

const PremioList = ({ counter, setCounter }: Props) => {
  const [premios, setPremios] = useState<Premio[]>([]);
  const [deleteCount, setDeleteCount] = useState(0);
  const [premioView, setPremioView] = useState(false);
  const [premioViewID, setPremioViewID] = useState<number>(0);
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const generatePageNumbers = () => {
    return Array.from(
      { length: 5 },
      (_, index) => currentPage - 2 + index
    ).filter((page) => page >= 1 && page <= totalPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    getPremios(page);
  };

  const getPremios = async (page = 1) => {
    try {
      const query = `page=${page}&search=${encodeURIComponent(
        searchText
      )}&state=${encodeURIComponent(statusFilter)}`;

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/premios/?${query}`,
        {
          headers: { "auth-token": sessionStorage.getItem("token") },
        }
      );
      setCurrentPage(response.data.pageInfo.currentPage);
      setTotalPages(response.data.pageInfo.totalPages);
      setPremios(response.data.data);
    } catch (error) {
      if (error === "Error: Request failed with status code 404") {
        console.log("No existe el usuario");
      }
    }
  };

  const deletePremio = async (premio_id: any) => {
    try {
      Swal.fire({
        title: "Procesando...",
        text: "No cierre esta ventana.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/premios/${premio_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setDeleteCount((prevCount) => prevCount + 1);
      Swal.fire({
        icon: "success",
        title: "Eliminado",
        text: "Se ha eliminado el premio",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al eliminar el premio.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getPremios(currentPage);
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [searchText, statusFilter, deleteCount, counter]);

  useEffect(() => {
    getPremios(currentPage);
  }, []);

  return (
    <div className="flex flex-col">
      <div>
        <input
          type="text"
          placeholder="Buscar"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="main-input p-2"
        />
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="main-dropdown my-2"
        >
          <option value="">Todos</option>
          <option value="Activo">Activos</option>
          <option value="Inactivo">Inactivos</option>
        </select>
      </div>

      <div
        className="overflow-auto bg-white pb-8 md:pb-0"
        style={{ maxHeight: "calc(65vh)" }}
      >
        <table className="w-full">
          <thead className="bg-gray-50 text-left">
            <tr className="table-head sticky top-0 bg-gray-50">
              <th></th>
              <th>ID</th>
              <th>Nombre</th>
              <th className="text-center">Puntos</th>
              <th className="text-center">Cantidad</th>
              <th className="text-center">Acciones</th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200">
            {premios.length === 0 ? (
              <tr>
                <td className="text-center" colSpan={6}>
                  No hay premios disponibles
                </td>
              </tr>
            ) : (
              premios.map((premio) => (
                <tr
                  className="hover:bg-slate-100 table-body"
                  key={premio.premio_id}
                >
                  <td>
                    <div className="m-auto w-fit">
                      <Status status={premio.estado} />
                    </div>
                  </td>
                  <td>{premio.premio_id}</td>
                  <td>{premio.nombre}</td>
                  <td className="text-center">
                    {premio.puntos.toLocaleString()}
                  </td>
                  <td className="text-center">{premio.cantidad}</td>

                  <td>
                    <div className="flex gap-2 m-auto w-fit">
                      <button
                        onClick={() => {
                          setPremioView(!premioView);
                          setPremioViewID(premio.premio_id);
                        }}
                      >
                        <ViewIcon />
                      </button>
                      <button
                        onClick={() => {
                          Swal.fire({
                            title: "¿Estás seguro?",
                            text: "No podrás revertir esta acción",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Sí, eliminar",
                            cancelButtonText: "Cancelar",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deletePremio(premio.premio_id);
                            }
                          });
                        }}
                      >
                        <Trash size={15} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div>
        {premios.length > 0 && (
          <div className="flex justify-center items-center gap-4 mt-4">
            <button
              className={`${
                currentPage === 1 ? "hidden" : "cefa-button w-fit"
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Anterior
            </button>

            <div className="flex gap-2">
              {generatePageNumbers().map((page) => (
                <button
                  key={page}
                  className={`cefa-button ${
                    currentPage === page ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>

            <button
              className={`${
                currentPage === totalPages ? "hidden" : "cefa-button w-fit"
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Siguiente
            </button>
          </div>
        )}
      </div>
      <View
        premioView={premioView}
        setPremioView={setPremioView}
        premioViewID={premioViewID}
        setCounter={setDeleteCount}
      />
    </div>
  );
};

export default PremioList;
