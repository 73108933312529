import { useEffect, useState } from "react";
import Add from "../../../../../icons/add";
import New from "./New";
import List from "./List";
import AddingFarmaciaInventory from "./AddingFarmaciaInventory";

const Productos = () => {
  const [view, setView] = useState(false);
  const [counter, setCounter] = useState(0);
  const [farmaciaInventario, setFarmaciaInventario] = useState(false);

  useEffect(() => {}, [counter]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-grow items-center py-4">
        <p className="font-bold grow text-2xl">
          <strong>Productos</strong>
        </p>
        <div>
          <button onClick={() => setView(!view)} className="cefa-button">
            <p>Agregar Producto</p>
            <Add />
          </button>
          <button
            onClick={() => setFarmaciaInventario(!farmaciaInventario)}
            className="cefa-button"
          >
            <p>Modificar Inventario</p>
          </button>
        </div>
      </div>
      <New
        view={view}
        setView={setView}
        counter={counter}
        setCounter={setCounter}
      />
      <AddingFarmaciaInventory
        isVisible={farmaciaInventario}
        setIsVisible={setFarmaciaInventario}
      />
      <div className="flex-grow overflow-hidden">
        <List counter={counter} setCounter={setCounter} />
      </div>
    </div>
  );
};

export default Productos;
