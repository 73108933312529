import { useState, useEffect } from "react";
import axios from "axios";
import ComprobanteView from "./View";
import ViewIcon from "../../../../../icons/view";
import { formatToMMDDYYYY } from "../../../../../utils/dates";

interface RetoComprobante {
  reto_comprobante_id: number;
  cantidad: number;
  fecha_creacion: string;
  estado: string;
  usuario: string;
  numero_factura: string;
  email: string;
}

const List = () => {
  const [retoComprobantes, setRetoComprobantes] = useState<RetoComprobante[]>(
    []
  );
  const [searchText, setSearchText] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [retoComprobanteView, setRetoComprobanteView] = useState(false);
  const [retoComprobanteViewID, setRetoComprobanteViewID] = useState<number>(0);
  const [deleteCount, setDeleteCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const generatePageNumbers = () => {
    return Array.from(
      { length: 5 },
      (_, index) => currentPage - 2 + index
    ).filter((page) => page >= 1 && page <= totalPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    getRetoComprobantes(page);
  };

  const getRetoComprobantes = async (page = 1) => {
    try {
      const query = `page=${page}&search=${encodeURIComponent(searchText)}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/reto_comprobantes?${query}`,
        {
          headers: { "auth-token": sessionStorage.getItem("token") },
        }
      );
      setCurrentPage(response.data.pageInfo.currentPage);
      setTotalPages(response.data.pageInfo.totalPages);
      setRetoComprobantes(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getRetoComprobantes(currentPage);
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [searchText, deleteCount]);

  useEffect(() => {
    getRetoComprobantes(currentPage);
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleFilterDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterDate(event.target.value);
  };

  return (
    <div className="px-2 flex flex-col">
      <div>
        <input
          className="main-input"
          type="text"
          placeholder="Buscar..."
          value={searchText}
          onChange={handleSearch}
        />
        <input
          className="main-input my-4"
          type="date"
          placeholder="Fecha..."
          value={filterDate}
          onChange={handleFilterDate}
        />
      </div>

      <div
        className="overflow-auto bg-white pb-8 md:pb-10"
        style={{ maxHeight: "calc(55vh)" }}
      >
        <table className="w-full">
          <thead className="bg-gray-50 text-left">
            <tr className="table-head sticky top-0 bg-gray-50">
              <th className="text-left">Estado</th>
              <th className="text-center">ID</th>
              <th className="text-left">Usuario</th>
              <th className="text-left">Email</th>
              <th className="text-left">Fecha de ingreso</th>
              <th className="text-center">Acciones</th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200">
            {retoComprobantes.length === 0 ? (
              <tr className="hover:bg-slate-100">
                <td className="text-center" colSpan={6}>
                  No hay comprobantes disponibles
                </td>
              </tr>
            ) : (
              retoComprobantes.map((comprobante: any) => (
                <tr
                  className="hover:bg-slate-100 table-body"
                  key={comprobante.reto_comprobante_id}
                >
                  <td>{comprobante.estado}</td>
                  <td className="text-center">
                    {comprobante.reto_comprobante_id}
                  </td>
                  <td>{comprobante.usuario}</td>
                  <td>{comprobante.email}</td>
                  <td>{formatToMMDDYYYY(comprobante.fecha_creacion)}</td>
                  <td>
                    <div className="flex gap-2 m-auto w-fit">
                      <button
                        onClick={() => {
                          setRetoComprobanteView(!retoComprobanteView);
                          setRetoComprobanteViewID(
                            comprobante.reto_comprobante_id
                          );
                        }}
                      >
                        <ViewIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div>
        {retoComprobantes.length > 0 && (
          <div className="flex justify-center items-center gap-4 mt-4">
            <button
              className={`${
                currentPage === 1 ? "hidden" : "cefa-button w-fit"
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Anterior
            </button>

            <div className="flex gap-2">
              {generatePageNumbers().map((page) => (
                <button
                  key={page}
                  className={`cefa-button ${
                    currentPage === page ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>

            <button
              className={`${
                currentPage === totalPages ? "hidden" : "cefa-button w-fit"
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Siguiente
            </button>
          </div>
        )}
      </div>
      <ComprobanteView
        retoComprobanteView={retoComprobanteView}
        setRetoComprobanteView={setRetoComprobanteView}
        retoComprobanteViewID={retoComprobanteViewID}
        setRetoComprobanteViewID={setRetoComprobanteViewID}
        counter={deleteCount}
        setCounter={setDeleteCount}
      />
    </div>
  );
};

export default List;
