import axios from "axios";
import { useEffect, useState } from "react";
import Trash from "../../../../../icons/trash";
import Swal from "sweetalert2";
import ViewIcon from "../../../../../icons/view";
import View from "./View";
import Status from "../../../../../icons/status";

type Usuario = {
  usuario_id: number;
  nombre: string;
  apellido: string;
  puntos: number;
  estado: string;
  farmacia: string;
  email: string;
};

interface Props {
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const UsuariosList = ({ counter, setCounter }: Props) => {
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  const [deleteCount, setDeleteCount] = useState(0);
  const [usuarioView, setUsuarioView] = useState(false);
  const [usuarioViewID, setUsuarioViewID] = useState<number>(0);

  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const generatePageNumbers = () => {
    return Array.from(
      { length: 5 },
      (_, index) => currentPage - 2 + index
    ).filter((page) => page >= 1 && page <= totalPages);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    getUsuarios(page);
  };

  const getUsuarios = async (page = 1) => {
    try {
      const query = `page=${page}&search=${encodeURIComponent(
        searchText
      )}&state=${encodeURIComponent(statusFilter)}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/usuarios/?${query}`,
        {
          headers: { "auth-token": sessionStorage.getItem("token") },
        }
      );
      setCurrentPage(response.data.pageInfo.currentPage);
      setTotalPages(response.data.pageInfo.totalPages);
      setUsuarios(response.data.data);
    } catch (error) {
      console.log(error);
      if (error === "Error: Request failed with status code 404") {
        console.log("No existe el usuario");
      }
    }
  };

  //Function that handles the delete action
  const deleteUsuario = async (usuario_id: any) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/usuarios/${usuario_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setDeleteCount((prevCount) => prevCount + 1);
      Swal.fire({
        icon: "success",
        title: "Eliminado",
        text: "Se ha eliminado el usuario.",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error.",
        confirmButtonText: "Aceptar",
      });
    }
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getUsuarios(currentPage);
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [searchText, statusFilter, deleteCount]);

  useEffect(() => {
    getUsuarios(currentPage);
  }, [counter, deleteCount]);

  return (
    <div className="px-2 flex flex-col">
      <div>
        <input
          type="text"
          placeholder="Buscar"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="main-input"
        />
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="main-dropdown my-2"
        >
          <option value="ambos">Todos</option>
          <option value="activo">Activos</option>
          <option value="inactivo">Inactivos</option>
        </select>
      </div>
      <div
        className="overflow-auto bg-white pb-8 md:pb-0"
        style={{ maxHeight: "calc(65vh)" }}
      >
        <table className="w-full">
          <thead className="bg-gray-50 top-0 sticky">
            <tr className="table-head text-left">
              <th></th>
              <th>ID</th>
              <th>Nombre</th>
              <th>Email</th>
              <th>Farmacia</th>
              <th>Puntos</th>
              <th>Acciones</th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-[#FAFAFA]">
            {usuarios.length === 0 ? (
              <tr className="hover:bg-slate-300">
                <td className="text-center w-full" colSpan={6}>
                  No hay usuarios disponibles
                </td>
              </tr>
            ) : (
              usuarios.map((usuario) => (
                <tr
                  className="hover:bg-slate-100 table-body "
                  key={usuario.usuario_id}
                >
                  <td>
                    <div className="m-auto w-fit">
                      <Status status={usuario.estado} />
                    </div>
                  </td>
                  <td>{usuario.usuario_id}</td>
                  <td>
                    {usuario.nombre} {usuario.apellido}
                  </td>
                  <td>{usuario.email}</td>
                  <td>{usuario.farmacia}</td>
                  <td className="text-center">
                    {usuario.puntos.toLocaleString()}
                  </td>
                  <td>
                    <div className="flex gap-2 justify-center">
                      <button
                        onClick={() => {
                          setUsuarioView(!usuarioView);
                          setUsuarioViewID(usuario.usuario_id);
                        }}
                      >
                        <ViewIcon />
                      </button>
                      <button
                        onClick={() => {
                          Swal.fire({
                            title: "¿Estás seguro?",
                            text: "No podrás revertir esta acción",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Sí, eliminar",
                            cancelButtonText: "Cancelar",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deleteUsuario(usuario.usuario_id);
                            }
                          });
                        }}
                      >
                        <Trash size={15} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div>
        {usuarios.length > 0 && (
          <div className="flex justify-center items-center gap-4 mt-4">
            <button
              className={`${
                currentPage === 1 ? "hidden" : "cefa-button w-fit"
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Anterior
            </button>

            <div className="flex gap-2">
              {generatePageNumbers().map((page) => (
                <button
                  key={page}
                  className={`cefa-button ${
                    currentPage === page ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>

            <button
              className={`${
                currentPage === totalPages ? "hidden" : "cefa-button w-fit"
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Siguiente
            </button>
          </div>
        )}
      </div>
      <View
        usuarioView={usuarioView}
        setUsuarioView={setUsuarioView}
        usuarioViewID={usuarioViewID}
        setCounter={setCounter}
      />
    </div>
  );
};

export default UsuariosList;
